import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Main from './main';

function App() {
  return (
      <BrowserRouter>
        <Routes>
            <Route path='/:id' element={<Main/>}/>
        </Routes>
      </BrowserRouter>
  );
}

export default App;
